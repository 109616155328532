import * as React from 'react';
import styled from 'styled-components';

import { ButtonSizableContent } from '../../../../../../Button/ButtonGeneral/ButtonSizableContent';
import { ButtonStyles } from '../../../../../../Button/ButtonStyles/ButtonStyles';
import { Link } from '../../../../../../Link';
import { colorsV2 } from '../../../../../../colors-v2';
import { ResponsiveTypography } from '../../../../../../Typography/v3/ResponsiveTypography';
import { SectionPromotionCommonProps } from '../../type';

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 36px 0 52px 40px;
`;

const RightSection = styled.div`
  position: relative;
  img {
    left: -80px;
    position: absolute;
    max-width: 100%;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 492px 664px;
`;

const Wrapper = styled.div`
  background-color: ${colorsV2.primary10};
  border-radius: 12px;
  overflow: hidden;
`;

const Action = ({ actionHref, actionOnClick, actionTitle, hrefOptions }) => {
  if (actionOnClick) {
    return (
      <ButtonStyles
        buttonStyle="filled"
        color="primary"
        colorStyle="bold"
        onClick={actionOnClick}
        content={<ButtonSizableContent text={actionTitle} />}
      />
    );
  }

  if (actionHref) {
    return (
      <Link href={actionHref} {...hrefOptions}>
        <ButtonStyles
          buttonStyle="filled"
          color="primary"
          colorStyle="bold"
          content={<ButtonSizableContent text={actionTitle} />}
        />
      </Link>
    );
  }

  return null;
};

export const SectionPromotionSignupShortNo = ({
  title,
  description,
  actionTitle,
  actionHref,
  hrefOptions,
  imageUrl,
  actionOnClick,
}: SectionPromotionCommonProps) => {
  return (
    <Wrapper>
      <Content>
        <LeftSection>
          <ResponsiveTypography.Paragraph className="title" variant="bold/32-40" color="black100">
            {title}
          </ResponsiveTypography.Paragraph>
          <ResponsiveTypography.Paragraph
            className="description"
            variant="regular/16-24"
            color="black80"
            mt="8px"
            mb="24px"
          >
            {description}
          </ResponsiveTypography.Paragraph>
          <div className="action">
            <Action
              actionOnClick={actionOnClick}
              actionHref={actionHref}
              actionTitle={actionTitle}
              hrefOptions={hrefOptions}
            />
          </div>
        </LeftSection>
        <RightSection>
          <img loading="lazy" alt="promotion-background" src={imageUrl} />
        </RightSection>
      </Content>
    </Wrapper>
  );
};
